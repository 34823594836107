






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseSelect from '@/components/forms/BaseSelect/BaseSelect.vue'
import {
  FillingItemInterface,
  StepThreeInterface,
} from '@/store/modules/room.module'
import FillingItem from '@/modules/MyRoomsPage/components/RoomForm/parts/FillingItem.vue'

import axios from '@/api/axios'

const validations = {
  filling: { required },
}

@Component({
  name: 'StepThree',
  components: { FormGroup, Field, BaseSelect, FillingItem },
  validations,
})
export default class StepThree extends Vue {
  @Prop({ required: true })
  value!: StepThreeInterface

  filling: FillingItemInterface[] | null = null
  prohibitions: FillingItemInterface[] = []

  /**
   * TODO: Fix adding item in filling list.
   * I'm working ~15h today and my brain is dead now.
   */
  fillingList: FillingItemInterface[] = []
  fetchingfillingList: FillingItemInterface[] = []

  public addFillingItem(id: number): void {
    const hasItem = this.fillingList.findIndex((item) => item.id === id) !== -1

    if (!hasItem) {
      const item = this.fetchingfillingList?.find((item) => item.id === id)

      this.fillingList?.push(item!)

      this.update()
    }

    this.filling = null
  }

  public deleteFillingItem(index: number): void {
    this.fillingList?.splice(index, 1)
    this.update()
  }

  public async fetchFillingOptions(): Promise<void> {
    try {
      await axios.get('/api/b2b/v1/features').then(({ data }) => {
        this.fetchingfillingList = data.data as FillingItemInterface[]
      })
    } catch (e) {
      console.error(e)
    }
  }

  public checkValidity(): boolean {
    return !this.$v.$anyError && this.fillingList.length > 0
  }

  public updateModel(data: StepThreeInterface): void {
    this.fillingList = data.filling
    this.prohibitions = data.prohibitions
  }

  public update(): void {
    this.$emit('input', {
      filling: this.fillingList,
      prohibitions: this.prohibitions,
    })
  }

  public created(): void {
    this.fetchFillingOptions()
    this.updateModel(this.value)
  }
}
